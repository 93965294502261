import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import styles from './ClientData.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import { IMaskInput } from 'react-imask';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../../components/Logo';
import { URL_SIMULATIONS } from '../../../../config';
import SpinnerDefault from '../../../../components/Spinner';

function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove non-numeric characters
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  let rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  return rest === parseInt(cpf.substring(10, 11));
}

export default function ClientDataCovenant() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const token = localStorage.getItem('token');

  const subtitleTextClient = "Informe o CPF do seu cliente.";

  const handleBack = () => {
    window.location.href = "/ConsultaUnitaria/Convenios/SelecaoBancos";
  }

const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setToastMessage('');

    // Verifica se o CPF está preenchido
    if (!cpf) {
        setToastMessage('Por favor, preencha o CPF.');
        return;
    }

    // Valida o CPF
    if (!isValidCPF(cpf)) {
        setToastMessage('CPF inválido.');
        return;
    }

    // Remove caracteres não numéricos do CPF
    const cpfOnlyNumbers = cpf.replace(/[^\d]/g, '');

    // Recupera dados do LocalStorage
    const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
    const covenantIdLocalStorage = formDataLocalStorage.covenantId;
    const covenantDescriptionLocalStorage = formDataLocalStorage.covenantDescription;

    // Recupera os bancos selecionados do LocalStorage
    const selectedBankNamesString = localStorage.getItem('selectedBankNames');
    const selectedBankNames = selectedBankNamesString ? JSON.parse(selectedBankNamesString) : [];

    // Prepara o formData para a requisição
    const formData = {
        cpf: cpfOnlyNumbers,
        covenantId: covenantIdLocalStorage,
        covenantDescription: covenantDescriptionLocalStorage,
        selectedBanks: selectedBankNames
    };

    // Salva os dados no LocalStorage para referência futura
    localStorage.setItem('formData', JSON.stringify(formData));

    // Define o estado de carregamento
    setLoading(true);

    // Cria um controller para timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // Timeout de 30 segundos

    try {
        // Realiza a requisição POST com timeout
        const response = await axios.post(`${URL_SIMULATIONS}/proposal/simulation`, formData, {
            signal: controller.signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        clearTimeout(timeoutId);

        window.location.href = `/ConsultaUnitaria/Convenio/Resultado?iS=${response.data.id}`;
    } catch (error) {
        if (axios.isCancel(error)) {
            setToastMessage('A requisição demorou muito e foi cancelada. Tente novamente.');
        } else {
            setToastMessage('Erro ao realizar a simulação.');
        }
    } finally {
        setLoading(false);
    }
};


  
  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Dados do Cliente</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='d-flex justify-content-center'>
            <Col sm={12} md={8}>
              <Form.Group className={styles.floatingLabel} controlId="cpf">
                <IMaskInput
                  mask="000.000.000-00"
                  className={`${styles.input} form-control`}
                  placeholder="CPF*"
                  value={cpf}
                  onAccept={(value: string) => setCpf(value)}
                />
                <Form.Label>CPF*</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>}
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-center mt-5'>
            {loading && 
              <SpinnerDefault/>
            }
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>}
                onClick={handleSubmit}
              />
        
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
