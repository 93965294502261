import React from 'react';
import styles from './Pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight, faEllipsis } from '@fortawesome/free-solid-svg-icons';

interface PaginationProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
}

const getTotalPages = (totalItems: number, itemsPerPage: number) =>
    Math.ceil(totalItems / itemsPerPage);

const Pagination: React.FC<PaginationProps> = ({
    totalItems,
    itemsPerPage,
    currentPage,
    onPageChange,
}) => {
    const totalPages = getTotalPages(totalItems, itemsPerPage);

    const handlePageClick = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const paginationItems = [];

    // Botão para a primeira página
    paginationItems.push(
        <li
            key="first"
            className={styles.paginationItem}
            onClick={() => handlePageClick(1)}
        >
            <FontAwesomeIcon icon={faAnglesLeft} className={styles.icon} />
        </li>
    );

    // Botão para a página anterior
    paginationItems.push(
        <li
            key="prev"
            className={styles.paginationItem}
            onClick={() => handlePageClick(currentPage - 1)}
        >
            <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
        </li>
    );

    if (totalPages <= 7) {
        // Mostrar todas as páginas se houver 7 ou menos
        for (let number = 1; number <= totalPages; number++) {
            paginationItems.push(
                <li
                    key={number}
                    className={`${styles.paginationItem} ${number === currentPage ? styles.active : ''}`}
                    onClick={() => handlePageClick(number)}
                >
                    {number}
                </li>
            );
        }
    } else {
        // Definir as páginas a serem exibidas com base na página atual
        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, currentPage + 1);

        // Ajustar as páginas iniciale final conforme a lista
        if (currentPage <= 2) {
            startPage = 1;
            endPage = 3;
        } else if (currentPage >= totalPages - 2) {
            startPage = totalPages - 2;
            endPage = totalPages;
        }

        // Gerar páginas dinâmicas
        for (let number = startPage; number <= endPage; number++) {
            paginationItems.push(
                <li
                    key={number}
                    className={`${styles.paginationItem} ${number === currentPage ? styles.active : ''}`}
                    onClick={() => handlePageClick(number)}
                >
                    {number}
                </li>
            );
        }

        // Adicionar reticências após a última página visível
        if (endPage < totalPages - 1) {
            paginationItems.push(
                <li key="ellipsis" className={`${styles.paginationItem} ${styles.ellipsis}`}>
                    <FontAwesomeIcon icon={faEllipsis} className={styles.icon} />
                </li>
            );
        }

        // Adiciona a última página, apenas se já não estiver visível
        if (endPage < totalPages) {
            paginationItems.push(
                <li
                    key={totalPages}
                    className={`${styles.paginationItem} ${currentPage === totalPages ? styles.active : ''}`}
                    onClick={() => handlePageClick(totalPages)}
                >
                    {totalPages}
                </li>
            );
        }
    }

    // Botão para a próxima página
    paginationItems.push(
        <li
            key="next"
            className={styles.paginationItem}
            onClick={() => handlePageClick(currentPage + 1)}
        >
            <FontAwesomeIcon icon={faChevronRight} className={styles.icon} />
        </li>
    );

    // Botão para a última página
    paginationItems.push(
        <li
            key="last"
            className={styles.paginationItem}
            onClick={() => handlePageClick(totalPages)}
        >
            <FontAwesomeIcon icon={faAnglesRight} className={styles.icon} />
        </li>
    );

    return (
        <Row className="justify-content-center mt-3">
            <Col xs="auto">
                <ul className={styles.pagination}>
                    {paginationItems}
                </ul>
            </Col>
        </Row>
    );
};

export default Pagination;