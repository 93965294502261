import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import styles from './Login.module.scss';
import axios from 'axios';
import lero from '../../assets/images/lero_ok (2).png';
import aceleraLogoMain from '../../assets/images/acelera_logo.png'; 
import ButtonLogin from "../../components/Buttons/ButtonLogin";
import ButtonGray from "../../components/Buttons/ButtonGray";
import ForgotMyPassword from "../../components/Modals/Others/ForgotMyPassword";
import { BASE_URL } from "../../config";

export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const passWordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const loginChange = (event: any) => {
        setLogin(event.target.value);
    };

    const passwordChange = (event: any) => {
        setPassword(event.target.value);
    }

    const isFormValid = () => {
        return login.trim() !== '' && password.trim() !== ''
    }

    const handleModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleForgotPasswordModal = () => {
        setShowForgotPasswordModal(true); // Show the ForgotMyPassword modal
    };

    const handleCloseForgotPasswordModal = () => {
        setShowForgotPasswordModal(false); // Close the ForgotMyPassword modal
    };

    const handdleLogin = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/logar`, {
                email: login,
                password: password
            });

            if (response.data.authorization) {
                localStorage.setItem('token', response.data.authorization);
                localStorage.setItem('isMenuOpen', 'true');
                window.location.href = '/PainelGeral';
            } else {
                setError('Token de autorização não encontrado na resposta');
            }
        } catch (error) {
            setError('Erro ao realizar login. Por favor, revisar as credenciais e tentar novamente');
            console.log(error);
        }
    };

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && isFormValid()){
            handdleLogin();
        }
    };

    return (
        <>
            <div className={`${styles.mainSection}`}>
                <div className={`${styles.mascotSection}`}>
                    <img src={lero} alt="Mascote" className={styles.imageMascot}/>
                </div>
                <div className={`${styles.loginContainer} p-5`}>
                    <img src={aceleraLogoMain} className="mb-5" alt="Logo" />
                    <div className={`${styles.inputGroup} mt-5`}>
                        <div className={`${styles.floatingLabel}`}>
                            <input
                                type="text"
                                id="login"
                                placeholder=" "
                                value={login}
                                onChange={loginChange}
                                className={`form-control ${styles.input}`}
                            />
                            <label htmlFor="login">Login</label>
                        </div>
                        <div className={`${styles.floatingLabel}`}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                placeholder=" "
                                value={password}
                                onChange={passwordChange}
                                onKeyDown={keyPress}
                                className={`form-control ${styles.input}`}
                            />
                            <label htmlFor="password">Senha</label>
                            <div
                                className={`${styles.icon}`}
                                onClick={passWordVisibility}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                            </div>
                        </div>
                    </div>
                    {error && <p className="text-danger text-center mt-4">{error}</p>}
                    <div className="mt-5">
                      <ButtonLogin
                          disabled={!isFormValid()}
                          onClick={handdleLogin}
                          content="Entrar"
                      />
                    </div>
                    <div className="row mt-4 text-center">
                        <span className={`${styles.spanAccess}`}> Meu Primeiro Acesso</span>
                    </div>
                    <div className="row text-center d-flex justify-content-between mb-4 mt-5">
                      <div className="col-sm-6 d-flex justify-content-end">
                          <span 
                              className={`${styles.spanLabel} me-5`} 
                              onClick={handleForgotPasswordModal} // Trigger the ForgotMyPassword modal
                          >
                              Esqueci minha senha
                          </span>
                      </div>
                      <div className="col-sm-6">
                          <span
                              className={`${styles.spanLabel} ml-5 text-truncate`}
                              style={{marginLeft: '30%'}}
                              onClick={handleModal}
                          >
                              Sobre o Acelereai
                          </span>
                      </div>
                      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                          <Modal.Header closeButton/>
                          <Modal.Body>
                              {/* Content for the About Acelereai modal can go here */}
                          </Modal.Body>
                          <Modal.Footer>
                              <ButtonGray content="Voltar" onClick={handleCloseModal} />
                          </Modal.Footer>
                      </Modal>
                    </div>

                    <div className="row mt-5">
                        <button className={`${styles.buttonPolicy}`}>Política de Privacidade</button>
                    </div>
                </div>
            </div>

            {/* Forgot My Password Modal */}
            <ForgotMyPassword onClose={handleCloseForgotPasswordModal} show={showForgotPasswordModal}/>
        </>
    );
}
