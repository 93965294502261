import { Container } from "react-bootstrap";
import NavBar from "../../components/NavBar";
import AccountTabPane from "./AccountTabPane";
import { useState } from "react";
import styles from './MyAccount.module.scss'
import Logo from "../../components/Logo";

export default function MyAccount() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    return(
        <>
          <section className={styles.navbarSection}>
            <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)}/>
          </section>
          <Logo/>
          <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
            <AccountTabPane />
          </Container>
        </>  
    )
}