import { Col, Row } from 'react-bootstrap';
import styles from './UpdateForm.module.scss';
import { useEffect, useState } from 'react';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../../components/ToastWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../../../../../config';

const UpdateForm = ({onProceed}: {onProceed: (page: string) => void;}) => {
    const token = localStorage.getItem('token');
    const apiId = localStorage.getItem('apiId');
    const bankId = localStorage.getItem('bankId');
    const bankUpdateName = localStorage.getItem('bankUpdateName');
    const keysEnabled = Number(bankId) === 6 || Number(bankId) === 1;

    const [toastMessage, setToastMessage] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [key, setKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [idBank, setIdBank] = useState('');

    const loginChange = (e: any) => {
        setLogin(e.target.value);
    }

    const passwordChange = (e: any) => {
        setPassword(e.target.value);
    }

    const keyChange = (e: any) => {
        setKey(e.target.value);
    }
    
    const secretKeyChange = (e: any) => {
        setSecretKey(e.target.value);
    }

    const passWordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const buttonDesable = () => {
        const fieldsIvalid = (login || '').trim() === '' || (password || '').trim() === ''; //desabilita o botão caso os campos de login e senha não estejam preenchidos
        const keysFieldInvalid = keysEnabled && ((key || '').trim() === '' || (secretKey || '').trim() === ''); //verifica qual o banco selecionado, para então, tornar obrigatório o preenchimento das keys
    
        return fieldsIvalid || keysFieldInvalid;
    };    

    const handleSearch = async () => {
        try {
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data = await res.json();
                const filteredData = data.find((item: any) => item.bank_api_credential_id === Number(apiId));
                if (filteredData) {
                    console.log(filteredData);
                    setData([filteredData]); 

                    setLogin(filteredData.user);
                    setPassword(filteredData.password);
                    setKey(filteredData.api_key);
                    setIdBank(filteredData.bank_id);
                } else {
                    setToastMessage('Nenhum dado encontrado!');
                }
            } else {
                console.log(res.statusText);
                setToastMessage('Nenhum dado encontrado!');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            setToastMessage('Nenhum dado encontrado!');
        }
    };

    const handleUpdate = async () => {
        try {
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco/${apiId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    bank_id: idBank,
                    user: login,
                    password: password,
                    api_key: key,
                    secret_key: secretKey,
                }),
            });

            if (res.ok) {
                const data = await res.json();
                console.log(data);
                setToastMessage('Dados atualizados com sucesso!');
                setTimeout(() => {
                    onProceed('access');
                    localStorage.removeItem('apiId');
                    localStorage.removeItem('bankUpdateName');
                    localStorage.removeItem('bankId');
                }, 2000)
            } else {
                console.log(res.statusText);
                setToastMessage('Erro ao atualizar dados!');
            }
        } catch(error){
            console.error('Erro na atualização:', error);
            setToastMessage('Erro ao atualizar dados!');
        }
    }

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && !buttonDesable()){
            handleUpdate();
        }
    };

    useEffect(() => {
        handleSearch();
    }, [apiId, token]);

    return(
        <>
            <div className={`${styles.title} d-flex justify-content-center mt-3 mb-2`}>
                <h2>{bankUpdateName}</h2>
            </div>
            <div className={styles.floatingLabel}>
                <input 
                    type="text" 
                    id="login" 
                    placeholder=" "
                    value={login}
                    onChange={loginChange}
                    className={`form-control ${styles.input}`}
                    />
                <label htmlFor="login">Login</label>
            </div>
            <div className={styles.floatingLabel}>
                <input 
                    type={showPassword ? 'text' : 'password'}
                    id="senha" 
                    placeholder=" "
                    value={password}
                    onChange={passwordChange}
                    onKeyDown={keysEnabled ? undefined : keyPress}
                    className={`form-control ${styles.input}`}
                    />
                <label htmlFor="senha">Senha</label>
                <div
                    className={`${styles.icon}`}
                    onClick={passWordVisibility}
                >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </div>
            </div>
            {keysEnabled && (
                <>
                    <div className={styles.floatingLabel}>
                        <input 
                            type="text" 
                            id="key" 
                            placeholder=" "
                            value={key}
                            onChange={keyChange}
                            className={`form-control ${styles.input}`}
                        />
                        <label htmlFor="key">Key</label>
                    </div>
                    <div className={styles.floatingLabel}>
                        <input 
                            type="text" 
                            id="secretKey" 
                            placeholder=" "
                            value={secretKey}
                            onChange={secretKeyChange}  
                            onKeyDown={keysEnabled ? keyPress : undefined}
                            className={`form-control ${styles.input}`}
                        />
                        <label htmlFor="secretKey">Secret Key</label>
                    </div>
                </>
            )}
            <Row>
                <Col className="d-flex justify-content-center mt-3">
                    <ButtonPrimary
                        disabled={buttonDesable()}
                        content="Salvar informações" 
                        onClick={() => handleUpdate()}
                    />
                </Col>
            </Row>
            
        {toastMessage && <ToastWarning message={toastMessage} />}
        </>
    )
}

export default UpdateForm;