import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './CreateAccess.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import leroFeliz from '../../../../assets/images/lero-feliz.png'

interface CreateAccessProps {
  show: boolean;
  onClose: () => void;
}

const CreateAccess: React.FC<CreateAccessProps> = ({ show, onClose }) => {
  return (
    <Modal className={styles.modalGeral} show={show} onHide={onClose}>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title>Dados salvos!</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.rowLero}>
            <div className='d-flex justify-content-center'>
              <img className={styles.imageModal} src={leroFeliz}/>
            </div>
        </div>
        <h5 className='text-center'>
          Seus dados de acesso bancário foram salvos! <br/>
          Clique em "Meus acessos" para visualizá-los.
        </h5>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAccess;
