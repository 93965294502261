import { useState, useEffect, ChangeEvent } from 'react';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SearchField from '../../../components/SearchField';
import Pagination from '../../../components/Pagination';
import { Container, CovenantCard, CheckIcon, CovenantName, CovenantLogo, SelectedCount } from './CovenantComponents';
import { URL_SIMULATIONS } from '../../../config';

const CovenantList = ({ onSelectionChange }: { onSelectionChange: (selectedId: string) => void }) => {
  const [selectedCovenant, setSelectedCovenant] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [covenants, setCovenants] = useState<Array<any>>([]);

  // Função para filtrar INSS e FGTS
  const filterCovenants = (data: Array<any>) => {
    return data.filter(covenant => covenant.covenantId !== '3' && covenant.covenantId !== '5' && covenant.covenantId !== '63');
  };

  // Efeito para buscar os convênios da API e filtrar os dados
  useEffect(() => {
    const fetchCovenants = async () => {
      try {
        const response = await fetch(`${URL_SIMULATIONS}/covenant/find-all`);
        const data = await response.json();
        const filteredData = filterCovenants(data);
        setCovenants(filteredData);
      } catch (error) {
        console.error('Erro ao buscar convênios:', error);
      }
    };

    fetchCovenants();
  }, []);

  // Armazenando convênio selecionado no localStorage
  useEffect(() => {
    if (selectedCovenant) {
      const selectedCovenantData = covenants.find(c => c.covenantId === selectedCovenant);
      if (selectedCovenantData) {
        const formData = JSON.parse(localStorage.getItem('formData') || '{}');
        formData.covenantId = selectedCovenantData.covenantId;
        formData.covenantDescription = selectedCovenantData.covenantDescription;
        localStorage.setItem('formData', JSON.stringify(formData));
        localStorage.setItem('formData', JSON.stringify(formData));
        onSelectionChange(selectedCovenantData.covenantId);
      }
    }
  }, [selectedCovenant, covenants, onSelectionChange]);

  const handleSelectCovenant = (covenantId: string) => {
    setSelectedCovenant(prevSelected => {
      if (prevSelected === covenantId) {
        localStorage.removeItem('covenantId');
        return null; 
      } else {
        localStorage.setItem('covenantId', covenantId);
        return covenantId; 
      }
    });
  };

  const filteredCovenants = covenants.filter(covenant =>
    covenant.covenantDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //variáveis para paginação
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredCovenants.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredCovenants.length);
  const currentItems = filteredCovenants.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const selectedConvenantName = covenants.find(convenant => convenant.covenantId === selectedCovenant)?.covenantDescription;

  return (
    <>
      <SearchField
        search={searchTerm}
        onSearchChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchTerm(e.target.value)
          setCurrentPage(1);
        }}
        placeholder="Pesquisar convênio..."
      />

      <Container>
        <Row className="d-flex justify-content-center">
          {currentItems.map(covenant => (
            <CovenantCard
              sm={12} md={3} key={covenant.covenantId}
              onClick={() => handleSelectCovenant(covenant.covenantId)}
              className={`selectedCovenant === covenant.covenantId ? 'selected' : '' me-5 mb-5 mt-2`}
              aria-label={`Select ${covenant.covenantDescription}`}
            >
              <CheckIcon
                icon={faCheck}
                className={selectedCovenant === covenant.covenantId ? 'selected' : ''}
                aria-hidden="true"
              />
              {covenant.path && (
                <CovenantLogo
                  src={covenant.path}
                  alt={covenant.covenantDescription}
                />
              )}
              <CovenantName>{covenant.covenantDescription}</CovenantName>
            </CovenantCard>
          ))}
        </Row>
      </Container>
      {selectedCovenant && (
        <SelectedCount>
          <span>Convênio selecionado: {selectedConvenantName}</span>
        </SelectedCount>
      )}
      {totalPages > 1 && (
          <Pagination
              totalItems={filteredCovenants.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
          />
      )}
    </>
  );
};

export default CovenantList;