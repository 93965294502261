import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './MyAccess.module.scss';
import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../components/ToastWarning';
import ButtonDelete from '../../../../../components/Buttons/ButtonDelete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../../../components/Pagination';
import SearchField from '../../../../../components/SearchField';
import { BASE_URL } from '../../../../../config';
import SpinnerDefault from '../../../../../components/Spinner';

interface MyAccessProps {
    onProceed: any;
}

const MyAccess: React.FC<MyAccessProps> = ({ onProceed }) => {
    const [toastMessage, setToastMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [access, setAccess] = useState<{ 
        api_id: number; 
        bank_id: number; 
        user: string; 
        password: string; 
        api_key: string; 
        bank_name: string; 
        img: string; 
    }[]>([]);
    const [showPassword, setShowPassword] = useState<number | null>(null);;
    const token = localStorage.getItem('token');
    const apiId = localStorage.getItem('apiId');
    const [search, setSearch] = useState<string>('');
    const[loading, setLoading] = useState<boolean>(true);
    
    const filteredAccess = access.filter(acces => 
        acces.bank_name.toLowerCase().includes(search.toLowerCase())
    );
    
    //variáveis para paginação
    const itemsPerPage = 2;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredAccess.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredAccess.length);
    const currentItems = filteredAccess.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleModal = () => {
        setShowModal(true);
        setToastMessage('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        localStorage.removeItem('apiId');
        handleSearch();
    };

    const passWordVisibility = (id: number) => {
        if (showPassword === id) {
            setShowPassword(null); 
        } else {
            setShowPassword(id); 
        }
    };

    const handleSearch = async () => {
        try {
            setLoading(true);
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data = await res.json();
                setAccess(data.map((access: any) => ({
                    api_id: access.bank_api_credential_id,
                    bank_id: access.bank_id,
                    user: access.user,
                    password: access.password,
                    api_key: access.api_key,
                    bank_name: access.bank.name,
                    img: access.bank.image_path,
                })));
            } else {
                console.log(res.statusText);
                setToastMessage('Nenhum dado encontrado!');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            setToastMessage('Nenhum dado encontrado!');
        } finally{
            setLoading(false);
        }
    };

    const handleRemove = async () => {
        try{
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco/${apiId}`,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if(res.ok){
                setToastMessage('Acesso removido com sucesso!');
            } else{
                console.log(res.statusText);
                setToastMessage('Erro na remoção do acesso!');
            }
        } catch(error){
            console.error('Erro na remoção:', error);
            setToastMessage('Erro ao remover o acesso!');
        }

        setTimeout(() => {
            handleCloseModal();
        }, 1000);

        handleSearch();
        setCurrentPage(1);
    }

    useEffect(() => {
        handleSearch();
    }, []);

    return (
        <>
            <SearchField
                search={search}
                onSearchChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value)
                    setCurrentPage(1);
                }}
                placeholder="Pesquisar por banco..."
            />
            <Container className="mt-4">
                {loading ? (
                        <SpinnerDefault/>
                    ) : (
                        <Row className="d-flex justify-content-between">
                            {currentItems.map(access => (
                                <Col key={access.api_id} md={6} className="mb-4">
                                    <Card className={`${styles.card} d-flex justify-content-center`}>
                                        <div className="d-flex justify-content-center">
                                            <Card.Img variant="top" src={access.img} className={styles.imgCard} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title className={`${styles.title} d-flex justify-content-center mb-1`}>{access.bank_name}</Card.Title>
                                            <Card.Text>
                                                <Row>
                                                    <Col className={styles.span}>Login:</Col>
                                                    <Col className={styles.text}>{access.user}</Col>
                                                </Row>
                                                <Row>
                                                    <Col className={styles.span}>Senha:</Col>
                                                    <input type={showPassword === access.api_id ? 'text' : 'password'} className={`${styles.text} col`} value={access.password} readOnly />
                                                    <div onClick={() => passWordVisibility(access.api_id)} className={styles.icon}>
                                                        <FontAwesomeIcon icon={showPassword === access.api_id ? faEyeSlash : faEye} />
                                                    </div>
                                                </Row>
                                                {access.api_key !== null &&
                                                    <Row>
                                                        <Col className={styles.span}>API Key:</Col>
                                                        <Col className={styles.text}>{access.api_key}</Col>
                                                    </Row>
                                                }
                                            </Card.Text>
                                        </Card.Body>
                                        <div className={`${styles.buttons} d-flex justify-content-between mt-2 mb-2`}>
                                            <div className="d-flex justify-content-start">
                                                <ButtonPrimary
                                                    onClick={() => {
                                                        localStorage.setItem('apiId', access.api_id.toString());
                                                        localStorage.setItem('bankUpdateName', access.bank_name);
                                                        onProceed('form');
                                                    }}
                                                    content={<FontAwesomeIcon icon={faEdit} />}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <ButtonDelete 
                                                    content={<>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </>}
                                                    onClick={() => {
                                                        localStorage.setItem('apiId', access.api_id.toString());
                                                        handleModal();
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                    <Modal show={showModal} onHide={handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Excluir Acesso?</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Footer>
                                            <ButtonPrimary onClick={handleRemove} content="Excluir"/>
                                            <ButtonDelete onClick={handleCloseModal} content="Cancelar"/>
                                        </Modal.Footer>
                                        {toastMessage && <ToastWarning message={toastMessage} />}
                                    </Modal>
                                </Col>
                            ))}
                        </Row>
                    )
                }
            </Container>
            {totalPages > 1 && (
                <Row className="justify-content-center mt-3">
                    <Col xs="auto" className={styles.pagination}>
                    <Pagination
                        totalItems={access.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        />
                    </Col>
                </Row>
            )}
            <div className="d-flex justify-content-end mt-4">
                <ButtonPrimary 
                    onClick={() => {
                        onProceed('list')
                        localStorage.removeItem('apiId')
                    }} 
                    content="Criar Novo Acesso" />
            </div>
        </>
    );
};

export default MyAccess;
