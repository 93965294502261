import React from 'react';
import styles from './ButtonDelete.module.scss';
import { Button } from 'react-bootstrap';

interface ButtonDeleteProps {
  content: any;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonDelete: React.FC<ButtonDeleteProps> = ({ content, onClick, disabled }) => {
  return (
    <Button
      className={`btn ${styles.buttonDelete}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default ButtonDelete;