import { Container } from 'react-bootstrap';
import logo from '../../assets/images/acelera_logo.png'

const Logo = () => {
    return(
        <Container className="d-flex justify-content-center mt-2 mb-3">
                <img src={logo} alt="Logo" />
        </Container>
    ) 
}

export default Logo;