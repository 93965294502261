import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import styles from './ResultTable.module.scss';
import ButtonPrimary from '../../../Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

interface CovenantData {
  cpf: string;
  covenantId: string | null;
  covenantDescription?: string;
  message?: string;
}

interface ResultTableProps {
  data: CovenantData[];
}

export default function ResultTableSearchCovenant({ data }: ResultTableProps) {

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        'http://webapi.gfttech.com.br:3333/api/portal/createFile/excel',
        {
          fileName: 'VALIDACAO_CONVENIOS_ACELEREAI',
          pageName: 'ListagemConveniosClientes',
          pageHeader: ['CPF', 'CONVENIO'],
          data: data.map(item => ({
            CPF: item.cpf,
            CONVENIO: item.covenantDescription || item.message || 'N/A'
          }))
        },
        {
          responseType: 'blob',
        }
      );

      const fileBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'validacao_convenios_acelereai.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.scrollableTable}>
          <Table className={`${styles.tableStyle} table table-responsive`}>
            <thead>
              <tr>
                <th>CPF</th>
                <th>CONVÊNIO</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.cpf}</td>
                  <td>{item.message || item.covenantDescription || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col className='d-flex justify-content-end mt-4'>
            <ButtonPrimary content={<FontAwesomeIcon icon={faDownload} />} onClick={handleDownload} />
          </Col>
        </Row>
      </div>
    </>
  );
}
